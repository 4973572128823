import dayjs from 'dayjs';

/* eslint-disable no-console */
const graphqlNextUrlProd = 'https://coastlineacademy.com/api/graphql';
const graphqlNextUrlDev = 'https://nextdev.coastlineacademy.com/api/graphql';
const graphqlNextUrlTest = 'http://127.0.0.1:8000/api/graphql';

const serverUrlProd = 'https://us-central1-coastline-new-driver.cloudfunctions.net/api';
const serverUrlDev = 'https://us-central1-coastline-new-driver-dev.cloudfunctions.net/api';
const serverUrlTest = 'https://us-central1-coastline-new-driver-test.cloudfunctions.net/api';
const localUrlTest = 'http://127.0.0.1:5001/coastline-new-driver-test/us-central1/api';

const ENV = process.env.REACT_APP_ENV;

export const schedulingApiUrl = 'https://scheduleoptimizerservice-mqq275yvla-wl.a.run.app';
export const COASTLINE_SERVICE_URL = 'https://coastlineservice-mqq275yvla-wl.a.run.app';
export const COASTLINE_GATEWAY_URL = 'https://coastline-complete-gateway-4vq3k8kn.uc.gateway.dev';

let projectUrl;
let localSchedulingApiKey = 'coastline-dev-key';
let localCoastlineServiceApiKey;
let localGraphqlNextUrl;
let localRefundAmountSuggestionEnabled = true;

if (ENV === 'production') {
  projectUrl = serverUrlProd;
  localSchedulingApiKey = 'coastline-api-key-123';
  localCoastlineServiceApiKey = 'DjEca548gy7sPD3CeMFb';
  localGraphqlNextUrl = graphqlNextUrlProd;
  localRefundAmountSuggestionEnabled = false;
} else if (ENV === 'test') {
  // eslint-disable-next-line no-restricted-globals
  if (location.hostname === '127.0.0.1') {
    console.log('Using LOCAL server');
    projectUrl = localUrlTest;
    localGraphqlNextUrl = graphqlNextUrlTest;
    // eslint-disable-next-line no-restricted-globals
  } else if (location.hostname === 'localhost') {
    throw new Error('using wrong hostname: localhost');
  } else {
    console.log('Using test server');
    projectUrl = serverUrlTest;
    localGraphqlNextUrl = graphqlNextUrlDev;
  }
  localSchedulingApiKey = 'coastline-test-key';
  localCoastlineServiceApiKey = 'cst-test-key-123';
} else if (ENV === 'development') {
  console.log('Using dev server');
  localSchedulingApiKey = 'coastline-dev-key';
  projectUrl = serverUrlDev;
  localCoastlineServiceApiKey = 'cst-dev-key-123';
  localGraphqlNextUrl = graphqlNextUrlDev;
} else {
  console.error('Unknown environment');
  projectUrl = serverUrlDev;
  localCoastlineServiceApiKey = 'cst-dev-key-123';
  localGraphqlNextUrl = graphqlNextUrlDev;
}
export const serverUrl = projectUrl;
export const schedulingApiKey = localSchedulingApiKey;
export const coastlineServiceApiKey = localCoastlineServiceApiKey;
export const graphqlNextUrl = localGraphqlNextUrl;
export const gatewayPublicApiKey = 'AIzaSyAZCMFHpSKTLytYE1ndFEwyxN6AdqsqfUE';

export const phoneNumber = '(800) 489-1896';
export const collisionNumber = '(760) 437-6170';
export const defaultPrice = 65;
export const officeHours = 'from 7am to 8pm Pacific Time, 7 days a week';
export const phoneHours = 'from 8am to 6pm Pacific Time, Monday through Friday';
export const DEFAULT_TIMEZONE = 'America/Los_Angeles';

export const genericUserErrorMessage = `An error has occurred on our end, please contact support at: ${phoneNumber}.`;
export const genericUserErrorWithCode = (errorId?: string) => `An error has occurred on our end. Please contact support at: ${phoneNumber} ${errorId ? `and share this error code: ${errorId}:` : null}.`;
export const genericAdminErrorWithCode = (errorId?: string) => `${errorId ? `Error Id ${errorId}:` : null} An error occured. Engineering has been notified. Share this error Id to help us debug the issue.`;

export const supportSlackChannel = '#support';

export const googleMerchantId = '128024605';

export const cloudinaryKey = '723563355487971';
export const cloudinaryUrl = 'https://api.cloudinary.com/v1_1/coastline-academy/image/upload';
export const cloudinaryUploadPreset = 'pkiglwvt';

export const extremeIpLookupKey = '1P0K4AKrmueVO5ltTCuv';

// Don't let someone book a 9am lesson until the afternoon lesson is booked
// Only applied September through April.
export const preferAfternoons = (() => {
  const now = dayjs();
  const month = now.month(); // 0-11
  return month >= 8 || month <= 3; // Sept (8) through April (3)
})();

export const refundAmountSuggestionEnabled = localRefundAmountSuggestionEnabled;

export const GC_SOURCES = {
  'drivers-ed': {
    label: 'DriversEd.com',
  },
  aceable: {
    label: 'Aceable',
  },
  'i-drive-safely': {
    label: 'iDriveSafely',
  },
  coastline: {
    label: 'Coastline Academy',
  },
};

export const DE_LABEL = 'Drivers Ed Course';
export const DE_COURSE_ID = 'XnJNIhIAACkAd6z2';
export const TEACHABLE_COURSE_ID = '466632';
export const PRISMIC_URL = 'https://driversed.cdn.prismic.io/api/v2';
// for content editing.
export const DATO_URL = 'https://coastline-courses.admin.datocms.com/editor/item_types/1620977/items/';
export const PRISMUC_BASE_URL = 'https://driversed.prismic.io/documents~c=published&l=en-us/';

export const PURCHASE_LOG_CATEGORIES = [
  {
    id: 'stripe',
    label: 'Stripe',
  },
  {
    id: 'paypal',
    label: 'Paypal',
  },
  {
    id: 'voucher',
    label: 'Voucher',
  },
  {
    id: 'charter',
    label: 'Charter School',
  },
  {
    id: 'corporate',
    label: 'Organization',
  },
  {
    id: 'cashCheck',
    label: 'Cash or Check',
  },
  {
    id: 'comp',
    label: 'Complimentary',
  },
  {
    id: 'transfer',
    label: 'Transfer',
  },
  {
    id: 'acquisition',
    label: 'Acquisition Prepaid',
  },
  {
    id: 'na',
    label: 'Not Applicable',
  },
];

export const DAYS_AT_A_TIME = 4;

export const CLOSED_COUNTIES = [];

export const TIMEZONES = [
  { label: 'Pacific', timezones: ['America/Los_Angeles'] },
  { label: 'Central', timezones: ['America/Chicago'] }, // most of Texas.
  { label: 'Mountain', timezones: ['America/Denver'] },
  { label: 'Eastern', timezones: ['America/New_York'] },
];

export const DEFAULT_MEETING_POINT_RADIUS_MILES = 30;

// This is actually number of 5-star reviews
export const NUM_REVIEWS = '86,000';
export const NUM_STUDENTS = '150,000';

export const CONTACT_MODAL_OPENED_VALUE = 10;

export const SUPPORT_EMAIL = 'hello@coastlineacademy.com';

export const SAMSARA_BASE_URL = 'https://cloud.samsara.com/o/47792/devices';

export const DATO_GRAPHQL_BASE_URL = 'https://graphql.datocms.com';

export const DRIVERS_ED_COURSE_TYPE_ID = '90756890';

export const CLASSROOM_DE_COURSE_TYPE_ID = '148588255';

export const DEFAULT_MIN_LEAD_HOURS = 3;

export const DEFAULT_MAX_DURATION_MINUTES = 3 * 120;

export const INITIATED_BYS = [
  'Student',
  'Instructor',
  'Coastline',
  'Immediate Reschedule',
];

export const MAPBOX_ACCESS_TOKEN = 'pk.eyJ1IjoiaGFydml0cm9uaXgiLCJhIjoiY2phd3cwZDM5MG9iZzJxczdrN25ld3c2NCJ9.-Na1Ha6J3xz-v5nWadaiiQ';

export const USER_NAVIGATION = [
  {
    label: 'Home',
    href: '/',
    showInHeader: false,
    showInDrawer: true,
  },
  {
    label: 'Drivers Ed',
    href: '/courses',
    showInHeader: true,
    showInDrawer: true,
  },
  {
    label: 'Driving Lessons',
    href: '/book',
    showInHeader: true,
    showInDrawer: true,
  },
  {
    label: 'Book',
    href: '/location-search',
    showInHeader: true,
    showInDrawer: true,
  },
  {
    label: 'Pricing',
    href: '/pricing',
    showInHeader: true,
    showInDrawer: true,
  },
  {
    label: 'Reviews',
    href: '/coastline-academy-driving-school-reviews',
    showInHeader: true,
    showInDrawer: true,
  },
  {
    label: 'About',
    href: '/article/about-coastline-academy',
    showInHeader: true,
    showInDrawer: true,
  },
  {
    label: 'DMV Practice Test',
    href: '/dmv-practice-test',
    showInHeader: false,
    showInDrawer: true,
  },
  {
    label: 'How to Get a License',
    href: '/how-to-get-drivers-license',
    showInHeader: false,
    showInDrawer: true,
  },
  {
    label: 'Articles',
    href: '/articles',
    showInHeader: false,
    showInDrawer: true,
  },
  {
    label: 'Contact',
    href: '/contact',
    showInHeader: false,
    showInDrawer: true,
  },
  {
    label: 'Affiliates',
    href: '/affiliate-signup',
    showInHeader: false,
    showInDrawer: true,
  },
  {
    label: 'Sell Your School',
    href: '/article/selling-your-driving-school-to-coastline',
    showInHeader: false,
    showInDrawer: true,
  },
];

export const SCHOOL_LICENSE_NUMBERS = [
  {
    label: 'California License E2115',
    href: 'https://www.dmv.ca.gov/wasapp/olinq2/display.do?submitName=Display&ol=E2115~E~E2115~00',
  },
  {
    label: 'Colorado Certificate 9906',
  },
  {
    label: 'Texas Licenses C3371, C3371b, C3371c',
  },
  {
    label: 'Las Vegas License #PRDS00051916',
  },
  {
    label: 'Reno License #PRDS00051767',
  },
  {
    label: 'Missouri License #FL1436534',
  },
  {
    label: 'Indianapolis, IN License PDF',
    href: '/l-assets/coastline_academy_indianapolis_license.pdf',
  },
  {
    label: 'Valparaiso, IN License PDF',
    href: '/l-assets/coastline_academy_valparaiso_license.pdf',
  },
  {
    label: 'Illinois License #9505',
  },
  {
    label: 'Pennsylvania Reporting Code 300462730',
  },
];

// This is used to set configuration for state that isn't in the state config DB.
// It's here for small one-offs. It's called "OVERRIDES" so that we don't get
// confused and think it's the only state config.
type StateConfigOverrides = {
  [stateAbbv: string]: {
    rounding_resolution?: number;
  };
};
export const STATE_CONFIG_OVERRIDES: StateConfigOverrides = {
  CO: {
    rounding_resolution: 5,
  },
  IL: {
    rounding_resolution: 5,
  },
  IN: {
    rounding_resolution: 5,
  },
  PA: {
    rounding_resolution: 5,
  },
  TX: {
    rounding_resolution: 5,
  },
};

type StateConfig = {
  [stateAbbv: string]: {
    hasPermitApplication?: boolean;
    hasCompletionReport?: boolean;
    hasFacilityNumber?: boolean;
    isShareable?: boolean;
    hasPreRegNumber?: boolean;
    hasCourseNotary?: boolean;
    hasCourseComparisons?: boolean;
    hasRegistrationReqs?: boolean;
    hasILEnrollmentForms?: boolean;
    showMeetingPoints?: boolean;
    hasDEEnrollmentReqs?: boolean;
    doesRequireBreakWarning?: boolean;
    shortBreakLengthMin?: string;
    longBreakLengthMin?: string;
  };
};

export const STATES_CONFIG: StateConfig = {
  ca: {
    doesRequireBreakWarning: true,
    longBreakLengthMin: '30',
  },
  il: {
    hasPermitApplication: true,
    hasCompletionReport: true,
    hasFacilityNumber: true,
    doesRequireBreakWarning: false,
    isShareable: true,
    hasILEnrollmentForms: true,
    hasRegistrationReqs: true,
    shortBreakLengthMin: '0',
    longBreakLengthMin: '0',
  },
  co: {
    doesRequireBreakWarning: false,
    hasPreRegNumber: true,
    isShareable: true,
    hasDEEnrollmentReqs: true,
    hasRegistrationReqs: true,
    longBreakLengthMin: '30',
  },
  nv: {
    doesRequireBreakWarning: true,
    hasCourseNotary: true,
    isShareable: true,
    hasDEEnrollmentReqs: true,
    longBreakLengthMin: '30',
  },
  in: {
    doesRequireBreakWarning: false,
    isShareable: true,
    hasRegistrationReqs: true,
  },
  tx: {
    doesRequireBreakWarning: false,
    isShareable: true,
    hasCourseComparisons: true,
    hasRegistrationReqs: true,
    showMeetingPoints: true,
  },
  pa: {
    doesRequireBreakWarning: false,
    isShareable: false,
    longBreakLengthMin: '30',
  },
  mo: {
    doesRequireBreakWarning: false,
  },
};

export const DEFAULT_TX_BRANCH_ID = 'C3371';

export const ARE_COLORADO_ROAD_TESTS_ENABLED = false;
export const ARE_ROAD_TESTS_SELF_BOOKING_ENABLED = false;

export const CANNOT_MANAGE_AVAILS_MSG = 'Your availability is managed by your manager. Please reach out to make changes to your schedule.';

export const doEnforceHomePickupCredit = (state?: string | null) => {
  if (window.Cypress) {
    // Tests always enforce.
    return true;
  }

  // Otherwise we currently (April 2024) only want to enforce in some states.
  return state?.toUpperCase() === 'CO'
    || state?.toUpperCase() === 'PA';
};

export const FEATURE_FLAGS = {
  allowSharedLessonsSelfBooking: {
    isEnabled: (state: string) => state.toLowerCase() === 'tx',
  },
  allowInstructorSchedules: {
    isEnabled: !!window.Cypress || process.env.REACT_APP_ENV !== 'production',
  },
};

export const SUPER_ADMIN_USER_IDS = [
  'icOscDnVLOMUIq5nd4oyCpT57eX2', // Matt
  'YonLDJ5ub9JQXuTfjcWN14da4zZZ', // Matt on Test
  'e6BivHcWmBWwSArZjnDfmrzq0vF3', // Nigel
  'B7TXzW9RzrfAr0YUKdSC0L4Mno52', // Kelsey
  's5n8L8mPDzTmqq4Luw04NQUQr3n1', // Courtney
  's6dIZp9JlzRG9eRoLhGA5elgN0E3', // Joy
  'MxpROyaAqTMShuTDbGeY96lF3A32', // Byron
];

export function getImpersonationLink(token: string) {
  return `${window.location.origin}/impersonate/${token}`;
}
